// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingScheduledDateSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingAllocationEntryShippingScheduledDateSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingAllocationEntryShippingScheduledDateSchemaType = InferType<typeof formShippingShippingAllocationEntryShippingScheduledDateSchema>;
export const formShippingShippingAllocationEntrySchema = yup.object({
	shippingScheduledDate: formShippingShippingAllocationEntryShippingScheduledDateSchema,
}).required();
export type FormShippingShippingAllocationEntrySchemaType = InferType<typeof formShippingShippingAllocationEntrySchema>;
