import { formShippingShippingAllocationEntrySchema } from '../../schema/form/shipping/shippingAllocationEntry';
import { formShippingShippingPickingCreateSchema } from '../../schema/form/shipping/shippingPickingCreate';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { object, } from "yup";
import { ProcessLog } from "./process";

export type BatchRunnerResponse = {
	isSuccess: boolean;
	batchProcessLogId?: number;
};

const allocationApi = createApiInterface().request(formShippingShippingAllocationEntrySchema).response<BatchRunnerResponse>();
const allocationStatusApi = createApiInterface().request(object()).response<{list: ProcessLog[]}>();

const pickingApi = createApiInterface().request(formShippingShippingPickingCreateSchema).response<BatchRunnerResponse>();
const pickingStatusApi = createApiInterface().request(object()).response<{list: ProcessLog[]}>();

export const batchRunnerApi = createApiInterfaceMap({
	['/v1/batches/allocation/run']: allocationApi,
	['/v1/batches/picking/run']: pickingApi,

	['/v1/batches/allocation/status']: allocationStatusApi,
	['/v1/batches/picking/status']: pickingStatusApi,
});
