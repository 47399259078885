// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingDateSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingSagawaSummaryLabelShippingDateSchema = typeShippingDateSchema;
export type FormShippingShippingSagawaSummaryLabelShippingDateSchemaType = InferType<typeof formShippingShippingSagawaSummaryLabelShippingDateSchema>;
export const formShippingShippingSagawaSummaryLabelSchema = yup.object({
	shippingDate: formShippingShippingSagawaSummaryLabelShippingDateSchema,
}).required();
export type FormShippingShippingSagawaSummaryLabelSchemaType = InferType<typeof formShippingShippingSagawaSummaryLabelSchema>;
