/** メニューリストの定義 */

// #region --- import

// mui : icon
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

// Routing用リンク
import { getLinks } from 'Routing';
import { SideMenuInformation } from './SideMenu';
import { MenuOpenState } from './SideMenuProvider';

// #endregion --- import

// #region --- constatns

/** 選択時の背景色 */
export const selectMenuColor = '#e0e0e0';

// #endregion

// #region --- メニュー情報定義
interface MenuNameType {
	/** 入荷 */
	Arrival: number;
	/** 出荷 */
	Shipping: number;
	/** 入庫 */
	Receiving: number;
	/** 出庫 */
	Issuing: number;
	/** 在庫 */
	Stock: number;
	/** マスタ */
	Master: number;
	/** 商品マスタ */
	MasterProduct: number;
	/** 倉庫マスタ */
	MasterWarehouse: number;
	/** 仕入先マスタ */
	MasterClient: number;
	/** 顧客マスタ */
	MasterCustomer: number;
	/** 資産マスタ */
	MasterAsset: number;
	/** システム設定 */
	SystemSetting: number;
	/** システム保守 */
	SystemMaintenance: number;
	/** ユーザーマスタ */
	MasterUser: number;
	/** 会社マスタ */
	MasterCompany: number;
	/** 梱包マスタ */
	MasterPackaging: number;
	/** 配送業者マスタ */
	MasterDeliveryCompany: number;
	/** 荷主管理 */
	MasterShipper: number;
	/** 地域管理 */
	SystemMaintenanceRegion: number;
	/** 機能管理 */
	SystemMaintenanceFeature: number;
	[key: string]: number;
}

/** メニューの定義 */
export const menuIds: MenuNameType = {
	Arrival: 1,
	Shipping: 2,
	Receiving: 3,
	Issuing: 4,
	Stock: 5,
	Master: 6,
	SystemSetting: 7,
	SystemMaintenance: 8,
	MasterProduct: 9,
	MasterWarehouse: 10,
	MasterClient: 11,
	MasterCustomer: 12,
	MasterAsset: 13,
	MasterUser: 14,
	MasterCompany: 15,
	SystemMaintenanceRegion: 16,
	SystemMaintenanceFeature: 17,
	MasterPackaging: 18,
	StockTaking: 19,
	SetProduct: 20,
	MasterDeliveryCompany: 21,
	BreakSetProduct: 22,
	MasterShipper: 23,
	Restock: 24,
};
export type MenuIdsType = typeof menuIds[keyof typeof menuIds];

interface InnerMenuNameType {
	/** 商品メニュー */
	ProductMasterMenu: number;
}

export const innerMenuIds: InnerMenuNameType = {
	ProductMasterMenu: 1,
};

export type InnerMenuType = typeof innerMenuIds[keyof typeof innerMenuIds];

// #endregion

// #region --- function
/**
 * メニューごとのPropsを返却します。
 * @desc 追加することで、左メニューに表示されます。
 */
const getSideMenus = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation[] => {
	if (authorityId !== 1 || !isDesktopStyle)
		return [
			getShippingMenu(authorityId, isDesktopStyle),
			getArrivalMenu(authorityId, isDesktopStyle),
			/*
			getReceivingMenu(authorityId, isDesktopStyle),
			getIssuingMenu(authorityId, isDesktopStyle),
			*/
			getStockMenu(authorityId, isDesktopStyle),
			// getMasterMenu(authorityId, isDesktopStyle),
		];
	else
		return [
			getShippingMenu(authorityId, isDesktopStyle),
			getArrivalMenu(authorityId, isDesktopStyle),
			/*
			getReceivingMenu(authorityId, isDesktopStyle),
			getIssuingMenu(authorityId, isDesktopStyle),
			*/
			getStockMenu(authorityId, isDesktopStyle),
			// getMasterMenu(authorityId, isDesktopStyle),
			/*
			getSystemMaintenanceMenu(authorityId, isDesktopStyle),
			*/
		];
};

// #region --- 入荷
export const getArrivalMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().arrival;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.arrival.title.list',
				},
				/*
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.arrival.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.arrival.title.entry.result',
				},
				*/
				{
					icon: <EditIcon />,
					link: links.inspectionSearch ?? '',
					menuLabelId: 'schema.arrival.title.inspection',
				},
				{
					icon: <CloudUploadIcon />,
					link: links.csvUploads ?? '',
					menuLabelId: 'schema.shipping.title.upload',
				},
			];
		else
			return [
				/*
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.arrival.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.arrival.title.entry.result',
				},
				*/
				{
					icon: <EditIcon />,
					link: links.inspectionSearch ?? '',
					menuLabelId: 'schema.arrival.title.inspection',
				},
			];
	};

	return {
		menuId: menuIds.Arrival,
		titleLabelId: 'schema.arrival.title.menu',
		icon: <LocalShippingOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- 出荷
export const getShippingMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().shipping;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.shipping.title.list',
				},
				/*
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.shipping.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.shipping.title.entry.result',
				},
				{
					icon: <ListAltIcon />,
					link: links.singlePickingList ?? '',
					menuLabelId: 'schema.shipping.title.list.single.picking',
				},
				*/
				{
					icon: <ListAltIcon />,
					link: links.totalPickingList ?? '',
					menuLabelId: 'schema.shipping.title.list.total.picking',
				},
				{
					icon: <EditIcon />,
					link: links.inspectionSearch ?? '',
					menuLabelId: 'schema.shipping.title.inspection',
				},
				/*
				{
					icon: <EditIcon />,
					link: links.packagingInspection ?? '',
					menuLabelId: 'schema.shipping.title.inspection.packaging',
				},
				{
					icon: <ListAltIcon />,
					link: links.unAllocationList ?? '',
					menuLabelId: 'schema.shipping.title.list.allocation',
				},
				*/
				{
					icon: <EditIcon />,
					link: links.pickingSearch ?? '',
					menuLabelId: 'schema.shipping.title.picking',
				},
				{
					icon: <CloudUploadIcon />,
					link: links.csvUploads ?? '',
					menuLabelId: 'schema.shipping.title.upload',
				},
				{
					icon: <EditIcon />,
					link: links.shippingSagawaLabel ?? '',
					menuLabelId: 'schema.shipping.title.sagawa.label',
				},
				{
					icon: <EditIcon />,
					link: links.picking ?? '',
					menuLabelId: 'schema.shipping.title.pickingCreate',
				},
			];
		else
			return [
				/*
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.shipping.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.shipping.title.entry.result',
				},
				{
					icon: <ListAltIcon />,
					link: links.singlePickingList ?? '',
					menuLabelId: 'schema.shipping.title.list.single.picking',
				},
				{
					icon: <ListAltIcon />,
					link: links.totalPickingList ?? '',
					menuLabelId: 'schema.shipping.title.list.total.picking',
				},
				{
					icon: <EditIcon />,
					link: links.inspection ?? '',
					menuLabelId: 'schema.shipping.title.inspection',
				},
				{
					icon: <EditIcon />,
					link: links.packagingInspection ?? '',
					menuLabelId: 'schema.shipping.title.inspection.packaging',
				},
				{
					icon: <ListAltIcon />,
					link: links.unAllocationList ?? '',
					menuLabelId: 'schema.shipping.title.list.allocation',
				},
				*/
				{
					icon: <EditIcon />,
					link: links.pickingSearch ?? '',
					menuLabelId: 'schema.shipping.title.picking',
				},
				{
					icon: <EditIcon />,
					link: links.inspectionSearch ?? '',
					menuLabelId: 'schema.shipping.title.inspection',
				},
			];
	};

	return {
		menuId: menuIds.Shipping,
		titleLabelId: 'schema.shipping.title.menu',
		icon: <LocalShippingOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- 入庫
export const getReceivingMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().receiving;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.receiving.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.receiving.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.receiving.title.entry.result',
				},
				{
					icon: <EditIcon />,
					link: links.inspection ?? '',
					menuLabelId: 'schema.receiving.title.inspection',
				},
				{
					icon: <ListAltIcon />,
					link: links.group ?? '',
					menuLabelId: 'schema.receiving.issuing.group.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.groupEntry ?? '',
					menuLabelId: 'schema.receiving.issuing.group.title.entry',
				},
			];
		else
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.receiving.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.receiving.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.receiving.title.entry.result',
				},
				{
					icon: <EditIcon />,
					link: links.inspection ?? '',
					menuLabelId: 'schema.receiving.title.inspection',
				},
				{
					icon: <ListAltIcon />,
					link: links.group ?? '',
					menuLabelId: 'schema.receiving.issuing.group.title.list',
				},
			];
	};

	return {
		menuId: menuIds.Receiving,
		titleLabelId: 'schema.receiving.title.menu',
		icon: <ArchiveOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- 出庫
const getIssuingMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().issuing;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.issuing.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.issuing.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.issuing.title.entry.result',
				},
				{
					icon: <EditIcon />,
					link: links.inspection ?? '',
					menuLabelId: 'schema.issuing.title.inspection',
				},
				{
					icon: <ListAltIcon />,
					link: links.freeLocationList ?? '',
					menuLabelId: 'schema.issuing.title.list.free.location',
				},
			];
		else
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.issuing.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.entrySchedule ?? '',
					menuLabelId: 'schema.issuing.title.entry.schedule',
				},
				{
					icon: <EditIcon />,
					link: links.entryResult ?? '',
					menuLabelId: 'schema.issuing.title.entry.result',
				},
				{
					icon: <EditIcon />,
					link: links.inspection ?? '',
					menuLabelId: 'schema.issuing.title.inspection',
				},
				{
					icon: <ListAltIcon />,
					link: links.freeLocationList ?? '',
					menuLabelId: 'schema.issuing.title.list.free.location',
				},
			];
	};

	return {
		menuId: menuIds.Issuing,
		titleLabelId: 'schema.issuing.title.menu',
		icon: <UnarchiveOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- 在庫
export const getStockMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().stock;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.stock.title.list',
				},
				/*
				{
					icon: <EditIcon />,
					link: links.move ?? '',
					menuLabelId: 'schema.stock.move.menu',
				},
				*/
				{
					icon: <EditIcon />,
					link: links.stockAllocation ?? '',
					menuLabelId: 'schema.shipping.title.entry.allocation',
				},
				/*
				// セット組メニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.SetProduct,
						titleLabelId: 'schema.stock.set.product.title.menu',
						icon: <MenuIcon />,
						menuLink: links.setProductMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.setProductList ?? '',
								menuLabelId: 'schema.stock.set.product.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.setProductEntrySchedule ?? '',
								menuLabelId: 'schema.stock.set.product.title.entry.schedule',
							},
							{
								icon: <EditIcon />,
								link: links.setProductEntryResult ?? '',
								menuLabelId: 'schema.stock.set.product.title.entry.result',
							},
							{
								icon: <EditIcon />,
								link: links.setProductEntryInspection ?? '',
								menuLabelId: 'schema.stock.set.product.title.inspection',
							},
						],
					},
				},
				// セット崩しメニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.BreakSetProduct,
						titleLabelId: 'schema.stock.break.set.product.title.menu',
						icon: <MenuIcon />,
						menuLink: links.breakSetProductMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.breakSetProductList ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.breakSetProductEntrySchedule ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.entry.schedule',
							},
							{
								icon: <EditIcon />,
								link: links.breakSetProductEntryResult ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.entry.result',
							},
							{
								icon: <EditIcon />,
								link: links.breakSetProductEntryInspection ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.inspection',
							},
						],
					},
				},
				// 棚卸メニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.StockTaking,
						titleLabelId: 'schema.stock.taking.title.menu',
						icon: <MenuIcon />,
						menuLink: links.stockTakingMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.stockTakingList ?? '',
								menuLabelId: 'schema.stock.taking.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.entryStockTakingSchedule ?? '',
								menuLabelId: 'schema.stock.taking.title.entry.schedule',
							},
							{
								icon: <EditIcon />,
								link: links.entryStockTakingResult ?? '',
								menuLabelId: 'schema.stock.taking.title.entry.result',
							},
						],
					},
				},
				{
					icon: <ListAltIcon />,
					link: links.stockoutList ?? '',
					menuLabelId: 'schema.stock.stockout.title.list',
				},
				// 補充メニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.Restock,
						titleLabelId: 'schema.stock.restock.title.menu',
						icon: <MenuIcon />,
						menuLink: links.restockMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.restockList ?? '',
								menuLabelId: 'schema.stock.restock.title.list',
							},
						],
					},
				},
				*/
			];
		else
			return [
				{
					icon: <ListAltIcon />,
					link: links.list ?? '',
					menuLabelId: 'schema.stock.title.list',
				},
				{
					icon: <EditIcon />,
					link: links.move ?? '',
					menuLabelId: 'schema.stock.move.menu',
				},
				/*
				// セット組メニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.SetProduct,
						titleLabelId: 'schema.stock.set.product.title.menu',
						icon: <MenuIcon />,
						menuLink: links.setProductMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.setProductList ?? '',
								menuLabelId: 'schema.stock.set.product.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.setProductEntryResult ?? '',
								menuLabelId: 'schema.stock.set.product.title.entry.result',
							},
							{
								icon: <EditIcon />,
								link: links.setProductEntryInspection ?? '',
								menuLabelId: 'schema.stock.set.product.title.inspection',
							},
						],
					},
				},
				// セット崩しメニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.BreakSetProduct,
						titleLabelId: 'schema.stock.break.set.product.title.menu',
						icon: <MenuIcon />,
						menuLink: links.breakSetProductMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.breakSetProductList ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.breakSetProductEntryResult ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.entry.result',
							},
							{
								icon: <EditIcon />,
								link: links.breakSetProductEntryInspection ?? '',
								menuLabelId: 'schema.stock.break.set.product.title.inspection',
							},
						],
					},
				},
				// 棚卸メニュー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.StockTaking,
						titleLabelId: 'schema.stock.taking.title.menu',
						icon: <MenuIcon />,
						menuLink: links.stockTakingMenu ?? '',
						nestLevel: 1,
						innerContents: [
							{
								icon: <ListAltIcon />,
								link: links.stockTakingList ?? '',
								menuLabelId: 'schema.stock.taking.title.list',
							},
							{
								icon: <EditIcon />,
								link: links.entryStockTakingResult ?? '',
								menuLabelId: 'schema.stock.taking.title.entry.result',
							},
						],
					},
				},
				{
					icon: <ListAltIcon />,
					link: links.stockoutList ?? '',
					menuLabelId: 'schema.stock.stockout.title.list',
				},
				*/
			];
	};

	return {
		menuId: menuIds.Stock,
		titleLabelId: 'schema.stock.title.menu',
		icon: <Inventory2OutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- マスタ
export const getMasterMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().master;
	const baseContents = () => {
		if (isDesktopStyle)
			return [
				// #region --- 倉庫
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterWarehouse,
						titleLabelId: 'schema.master.warehouse.title.menu',
						icon: <MenuIcon />,
						menuLink: links.warehouseMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 倉庫
							{
								icon: <ListAltIcon />,
								link: links.warehouseList ?? '',
								menuLabelId: 'schema.master.warehouse.title.list',
							},
							// 倉庫区分
							{
								icon: <ListAltIcon />,
								link: links.warehouseCategoryList ?? '',
								menuLabelId: 'schema.master.warehouse.category.title.list',
							},
							// ロケーション
							{
								icon: <ListAltIcon />,
								link: links.locationList ?? '',
								menuLabelId: 'schema.master.location.title.list',
							},
							// ロケーション区分
							{
								icon: <ListAltIcon />,
								link: links.locationCategoryList ?? '',
								menuLabelId: 'schema.master.location.category.title.list',
							},
							/*
							// 棚
							{
								icon: <ListAltIcon />,
								link: links.locationDetailsList ?? '',
								menuLabelId: 'schema.master.location.detail.title.list',
							},
							// 通路(倉庫)
							{
								icon: <ListAltIcon />,
								link: links.aisleList ?? '',
								menuLabelId: 'schema.master.aisle.title.list',
							},
							// 保管条件
							{
								icon: <ListAltIcon />,
								link: links.storingConditionList ?? '',
								menuLabelId: 'schema.master.storingCondition.title.list',
							},
							*/
						],
					},
				},
				// #endregion

				// #region --- 仕入先
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterClient,
						titleLabelId: 'schema.master.client.title.menu',
						icon: <MenuIcon />,
						menuLink: links.clientMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 仕入先
							{
								icon: <ListAltIcon />,
								link: links.clientList ?? '',
								menuLabelId: 'schema.master.client.title.list',
							},
							// 仕入先区分
							{
								icon: <ListAltIcon />,
								link: links.clientCategoryList ?? '',
								menuLabelId: 'schema.master.client.category.title.list',
							},
							// 仕入先出荷元
							{
								icon: <ListAltIcon />,
								link: links.clientSenderList ?? '',
								menuLabelId: 'schema.master.client.sender.title.list',
							},
							// 仕入先輸送手段
							{
								icon: <ListAltIcon />,
								link: links.transportMethodList ?? '',
								menuLabelId: 'schema.master.client.transport.method.title.list',
							},
						],
					},
				},
				*/
				// #endregion

				// #region --- 商品
				{
					icon: <ListAltIcon />,
					link: links.productMenu ?? '',
					menuLabelId: '',
					isNestMenu: true,
					innerMenuValueType: innerMenuIds.ProductMasterMenu,
					nestMenu: {
						menuId: menuIds.MasterProduct,
						titleLabelId: 'schema.master.product.title.menu',
						icon: <MenuIcon />,
						menuLink: links.productMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 商品
							{
								icon: <ListAltIcon />,
								link: links.productList ?? '',
								menuLabelId: 'schema.master.product.title.list',
							},
							// 品番
							{
								icon: <ListAltIcon />,
								link: links.partNumberList ?? '',
								menuLabelId: 'schema.master.partNumber.title.list',
							},
							// 品番区分
							{
								icon: <ListAltIcon />,
								link: links.partNumberCategoryList ?? '',
								menuLabelId: 'schema.master.partNumber.category.title.list',
							},
							/*
							// 大項目
							{
								icon: <ListAltIcon />,
								link: links.productLargeCategoryList ?? '',
								menuLabelId: 'schema.master.product.large.category.title.list',
							},
							// 小項目
							{
								icon: <ListAltIcon />,
								link: links.productSmallCategoryList ?? '',
								menuLabelId: 'schema.master.product.small.category.title.list',
							},
							// 商品状態
							{
								icon: <ListAltIcon />,
								link: links.productStatusList ?? '',
								menuLabelId: 'schema.master.product.status.title.list',
							},
							// SKUグループ
							{
								icon: <ListAltIcon />,
								link: links.skuGroupList ?? '',
								menuLabelId: 'schema.master.sku.group.title.list',
							},
							*/
						],
					},
				},
				// #endregion

				// #region --- 顧客
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterCustomer,
						titleLabelId: 'schema.master.customer.title.menu',
						icon: <MenuIcon />,
						menuLink: links.customerMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 顧客
							{
								icon: <ListAltIcon />,
								link: links.customerList ?? '',
								menuLabelId: 'schema.master.customer.title.list',
							},
							// 顧客区分
							{
								icon: <ListAltIcon />,
								link: links.customerCategoryList ?? '',
								menuLabelId: 'schema.master.customer.category.title.list',
							},
							// 顧客配送先
							{
								icon: <ListAltIcon />,
								link: links.customerDeliveryDestinationList ?? '',
								menuLabelId: 'schema.master.customer.delivery.destination.title.list',
							},
						],
					},
				},
				*/
				// #endregion

				// #region --- 資産
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterAsset,
						titleLabelId: 'schema.master.asset.title.menu',
						icon: <MenuIcon />,
						menuLink: links.assetMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 資産
							{
								icon: <ListAltIcon />,
								link: links.assetList ?? '',
								menuLabelId: 'schema.master.asset.title.list',
							},
							// 資産区分
							{
								icon: <ListAltIcon />,
								link: links.assetCategoryList ?? '',
								menuLabelId: 'schema.master.asset.category.title.list',
							},
						],
					},
				},
				*/
				// #endregion

				// #region --- 梱包
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterPackaging,
						titleLabelId: 'schema.master.packaging.title.menu',
						icon: <MenuIcon />,
						menuLink: links.packagingMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 梱包区分
							{
								icon: <ListAltIcon />,
								link: links.packagingCategoryList ?? '',
								menuLabelId: 'schema.master.packaging.category.title.list',
							},
						],
					},
				},
				*/
				// #endregion --- 梱包

				// #region --- 配送業者
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterDeliveryCompany,
						titleLabelId: 'schema.master.delivery.company.title.menu',
						icon: <MenuIcon />,
						menuLink: links.deliveryCompanyMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 配送業者
							{
								icon: <ListAltIcon />,
								link: links.deliveryCompanyList ?? '',
								menuLabelId: 'schema.master.delivery.company.title.list',
							},
							// 配送拠点
							{
								icon: <ListAltIcon />,
								link: links.deliveryCompanyBaseList ?? '',
								menuLabelId: 'schema.master.delivery.company.base.title.list',
							},
						],
					},
				},
				*/
				// #endregion --- 配送業者

				// #region --- 荷主
				/*
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterShipper,
						titleLabelId: 'schema.master.shipper.title.menu',
						icon: <MenuIcon />,
						menuLink: links.shipperMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 荷主
							{
								icon: <ListAltIcon />,
								link: links.shipperList ?? '',
								menuLabelId: 'schema.master.shipper.title.list',
							},
							// 荷主契約
							{
								icon: <ListAltIcon />,
								link: links.shipperContractList ?? '',
								menuLabelId: 'schema.master.shipper.contract.title.list',
							},
						],
					},
				},
				*/
				// #endregion

				// #region --- ユーザー
				{
					icon: <ListAltIcon />,
					link: '',
					menuLabelId: '',
					isNestMenu: true,
					nestMenu: {
						menuId: menuIds.MasterUser,
						titleLabelId: 'schema.master.user.title.menu',
						icon: <MenuIcon />,
						menuLink: links.userMenu ?? '',
						nestLevel: 1,
						innerContents: [
							// 利用ユーザー
							{
								icon: <ListAltIcon />,
								link: links.systemUserList ?? '',
								menuLabelId: 'schema.master.user.title.list',
							},
							/*
							// ユーザー権限
							{
								icon: <ListAltIcon />,
								link: links.userAuthorityList ?? '',
								menuLabelId: 'schema.master.user.authority.title.list',
							},
							*/
						],
					},
				},
				// #endregion --- ユーザー

				// 入荷区分
				/*
				{
					icon: <ListAltIcon />,
					link: links.arrivalCategoryList ?? '',
					menuLabelId: 'schema.arrival.category.title.list',
				},
				*/

				// 検索条件
				/*
				{
					icon: <ListAltIcon />,
					link: links.searchConditionList ?? '',
					menuLabelId: 'schema.master.searchCondition.title.list',
				},
				*/

				// システム設定
				/*
				{
					icon: <SettingsIcon />,
					link: links.systemSettingList ?? '',
					menuLabelId: 'schema.master.system.setting.title',
				},
				*/
			];
		else
			return [
				/*
				// 倉庫
				{
					icon: <ListAltIcon />,
					link: links.warehouseList ?? '',
					menuLabelId: 'schema.master.warehouse.title.list',
				},
				// ロケーション
				{
					icon: <ListAltIcon />,
					link: links.locationList ?? '',
					menuLabelId: 'schema.master.location.title.list',
				},
				// ロケーション明細
				{
					icon: <ListAltIcon />,
					link: links.locationDetailsList ?? '',
					menuLabelId: 'schema.master.location.detail.title.list',
				},
				// 通路
				{
					icon: <ListAltIcon />,
					link: links.aisleList ?? '',
					menuLabelId: 'schema.master.aisle.title.list',
				},
				// 仕入先
				{
					icon: <ListAltIcon />,
					link: links.clientList ?? '',
					menuLabelId: 'schema.master.client.title.list',
				},
				// 顧客
				{
					icon: <ListAltIcon />,
					link: links.customerList ?? '',
					menuLabelId: 'schema.master.customer.title.list',
				},
				// 商品
				{
					icon: <ListAltIcon />,
					link: links.productList ?? '',
					menuLabelId: 'schema.master.product.title.list',
				},
				// 品番
				{
					icon: <ListAltIcon />,
					link: links.partNumberList ?? '',
					menuLabelId: 'schema.master.partNumber.title.list',
				},
				// 資産
				{
					icon: <ListAltIcon />,
					link: links.assetList ?? '',
					menuLabelId: 'schema.master.asset.title.list',
				},
				// 荷主
				{
					icon: <ListAltIcon />,
					link: links.shipperList ?? '',
					menuLabelId: 'schema.master.shipper.title.list',
				},
				*/
			];
	};

	return {
		menuId: menuIds.Master,
		titleLabelId: 'schema.master.title.menu',
		icon: <SettingsOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents(),
	};
};
// #endregion

// #region --- システム保守
export const getSystemMaintenanceMenu = (authorityId?: number, isDesktopStyle?: boolean): SideMenuInformation => {
	const links = getLinks().systemMaintenance;
	const baseContents = [
		// #region --- 会社
		{
			icon: <ListAltIcon />,
			link: '',
			menuLabelId: '',
			isNestMenu: true,
			nestMenu: {
				menuId: menuIds.MasterCompany,
				titleLabelId: 'schema.system.maintenance.company.title.menu',
				icon: <MenuIcon />,
				menuLink: links.companyMenu ?? '',
				nestLevel: 1,
				innerContents: [
					// 会社 (システム利用会社)
					{
						icon: <ListAltIcon />,
						link: links.companyList ?? '',
						menuLabelId: 'schema.system.maintenance.company.title.list',
					},
					// 自社情報
					// {
					// 	icon: <EditIcon />,
					// 	link: links.companyInformationEntry ?? '',
					// 	menuLabelId: 'schema.system.maintenance.company.information.title.entry',
					// },
				],
			},
		},
		// #endregion

		// #region --- 機能
		{
			icon: <ListAltIcon />,
			link: '',
			menuLabelId: '',
			isNestMenu: true,
			nestMenu: {
				menuId: menuIds.SystemMaintenanceFeature,
				titleLabelId: 'schema.system.maintenance.feature.title.menu',
				icon: <MenuIcon />,
				menuLink: links.featureMenu ?? '',
				nestLevel: 1,
				innerContents: [
					// 機能一覧
					{
						icon: <ListAltIcon />,
						link: links.featureList ?? '',
						menuLabelId: 'schema.system.maintenance.feature.title.list',
					},
					// 機能区分一覧
					{
						icon: <ListAltIcon />,
						link: links.featureCategoryList ?? '',
						menuLabelId: 'schema.system.maintenance.feature.category.title.list',
					},
				],
			},
		},

		// #endregion

		// #region --- 地域
		{
			icon: <ListAltIcon />,
			link: '',
			menuLabelId: '',
			isNestMenu: true,
			nestMenu: {
				menuId: menuIds.SystemMaintenanceRegion,

				titleLabelId: 'schema.system.maintenance.region.title.menu',
				icon: <MenuIcon />,
				menuLink: links.regionMenu ?? '',
				nestLevel: 1,
				innerContents: [
					// 国
					{
						icon: <ListAltIcon />,
						link: links.countryCodeList ?? '',
						menuLabelId: 'schema.system.maintenance.region.country.title.list',
					},
					// 都道府県
					{
						icon: <ListAltIcon />,
						link: links.prefecturesCodeList ?? '',
						menuLabelId: 'schema.system.maintenance.region.prefecture.title.list',
					},
				],
			},
		},
		// #endregion

		// 画面操作ログ
		{
			icon: <SettingsIcon />,
			link: links.operationLogList ?? '',
			menuLabelId: 'schema.system.maintenance.log.title',
		},
	];

	return {
		menuId: menuIds.SystemMaintenance,
		titleLabelId: 'schema.system.maintenance.title.menu',
		icon: <SettingsSuggestOutlinedIcon />,
		menuLink: links.menu ?? '',
		nestLevel: 0,
		innerContents: baseContents,
	};
};
// #endregion

// #endregion --- function

export default getSideMenus;
